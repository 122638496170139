import {httpProvider} from "@Newfiling/Services";

export const store = (data) => {
    return httpProvider.post('/ePropertydemands', data);
};

export const all = (data) => {
    return httpProvider.post('/AdminesearchinRequestaproperties', data);
};


export const find = (id) => {
    return httpProvider.get(`/ePropertydemands/${id}`);
};

export const destroy = (id) => {
    return httpProvider.delete(`/ePropertydemands/${id}`);
};

export const update = (id,data) => {
    return httpProvider.put(`/eEditPropertydemands/${id}`, data);
};




