<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="$emit('click')"
          fab
          small
          color="error"
          elevation="0"
      >
        <v-icon size="20">
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </template>
    <span>
        حذف
      </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DeleteButton"
}
</script>

<style scoped>

</style>