<template>
  <div class="page-login-desktop">
    <Table
        label="درخواست ها"
        icon="mdi-format-list-bulleted"
        :table="table">
      <template slot="header">
        <v-btn
            elevation="0"
            @click="filters.modal=true"
            color="main" large class="white--text">
          جستجو
        </v-btn>
      </template>
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.clientName }}</td>
        <td class="text-center">{{ item.title }}</td>
        <td class="text-center">{{ item.amount }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">
          <v-chip color="success" text-color="white" v-if="item.status">
            تایید شده
          </v-chip>
          <v-chip color="error" text-color="white" v-else>
            تایید نشده
          </v-chip>
        </td>
        <td class="text-center">

          <ViewButton
              @click="item.actions.view"
          />

          <EditButton
              @click="item.actions.edit"
          />


          <DeleteButton
              @click="item.actions.delete"
          />


        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این درخواست مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog v-model="filters.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-magnify
          </v-icon>
          جستجو
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field
                  outlined
                  label="نام مالک"
                  hide-details
                  v-model="filters.clientName"
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                  outlined
                  label="شماره مالک"
                  hide-details
                  v-model="filters.phone1"
              />
            </v-col>
            <v-col :cols="6">
              <v-select
                  outlined
                  label="نوع معامله"
                  hide-details
                  v-model="filters.TypeOfTransaction"
                  :items="fileTransactions"
                  item-value="id"
                  item-text="name"
              />
            </v-col>
            <v-col :cols="6">
              <v-select
                  outlined
                  label="نوع ملک"
                  hide-details
                  v-model="filters.PropertyType"
                  :items="fileTypes"
                  item-value="id"
                  item-text="name"
              />
            </v-col>
            <v-col :cols="6">
              <v-select
                  outlined
                  label="شماره طبقه"
                  hide-details
                  v-model="filters.FloorNumber1"
                  :items="floorNumberItems"
                  item-value="id"
                  item-text="name"
              />
            </v-col>
            <v-col :cols="6">
              <v-select
                  outlined
                  label="منظقه"
                  hide-details
                  v-model="filters.Locationsid1"
                  :items="locations"
                  item-value="id"
                  item-text="name"
              />
            </v-col>

            <v-col :cols="12">
              <label class="mb-4 font-weight-bold text-body-1">
                قیمت کل / رهن :
              </label>
              <div class="d-flex">
                <v-text-field
                    class="flex-grow-1"
                    outlined
                    label="حداقل قیمت"
                    hide-details
                    v-model="filters.MinimumSalesBudget"
                />
                <div class="flex-grow-0 text-body-1 font-weight-bold d-flex align-center justify-center pa-2">
                  تا
                </div>
                <v-text-field
                    class="flex-grow-1"
                    outlined
                    label="حداکثر قیمت"
                    hide-details
                    v-model="filters.MaximumSalesBudget"
                />
              </div>
            </v-col>

            <v-col :cols="12">
              <label class="mb-4 font-weight-bold text-body-1">
                قیمت متری / اجاره :
              </label>
              <div class="d-flex">
                <v-text-field
                    class="flex-grow-1"
                    outlined
                    label="حداقل قیمت"
                    hide-details
                    v-model="filters.MinimumRentBudget"
                />
                <div class="flex-grow-0 text-body-1 font-weight-bold d-flex align-center justify-center pa-2">
                  تا
                </div>
                <v-text-field
                    class="flex-grow-1"
                    outlined
                    label="حداکثر قیمت"
                    hide-details
                    v-model="filters.MaximumRentBudget"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="resetFilters"
              large text color="main">
            ریست
          </v-btn>


          <v-btn
              @click="fetchItems"
              elevation="0"
              large color="primary">
            جستجو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="demand.modal" max-width="900">

      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="d-flex align-center justify-start">
            <v-icon class="ml-2">
              mdi-information-outline
            </v-icon>
            <div class="font-weight-bold text-body-1">
              {{ demand.title }}
            </div>
          </div>

          <v-btn icon fab color="error" @click="demand.modal=false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>

        </v-card-title>

        <v-card-text>
          <v-row dense class="mb-6">
            <v-col :cols="12">
              <div class="mb-2 font-weight-bold text-body-1 black--text">
                اطلاعات درخواست :
              </div>
            </v-col>
            <v-col :cols="6">
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  نوع معامله :
                </div>
                <div class="item-value">
                  {{ demand.transactionType }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  نوع ملک :
                </div>
                <div class="item-value">
                  {{ demand.fileProperty }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  نام مراجعه کننده :
                </div>
                <div class="item-value">
                  {{ demand.username }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  شماره تماس :
                </div>
                <div class="item-value">
                  {{ demand.phoneNumber }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  مهلت زمان :
                </div>
                <div class="item-value">
                  {{ demand.deadline }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  روزهای بازدید :
                </div>
                <div class="item-value">
                  {{ demand.visitDays }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  محدوده مساحت زمین :
                </div>
                <div class="item-value">
                  {{ demand.landArea }}
                </div>
              </div>
            </v-col>

            <v-col :cols="6">
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  طبقه ملک درخواستی :
                </div>
                <div class="item-value">
                  {{ demand.floorNumber }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  سن بنا :
                </div>
                <div class="item-value">
                  {{ demand.age }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  درصد نقدینگی:
                </div>
                <div class="item-value">
                  {{ demand.liquidityPercentage }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  تعداد نفرات :
                </div>
                <div class="item-value">
                  {{ demand.numberofpeople }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  محدوده متراژ ملک :
                </div>
                <div class="item-value">
                  {{ demand.baseArea }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  محدوده قیمت کل / رهن :
                </div>
                <div class="item-value">
                  {{ demand.totalPrice }}
                </div>
              </div>
              <div class="demand-item">
                <div class="item-name">
                  <v-icon size="10">
                    mdi-circle
                  </v-icon>
                  محدوده قیمت متری / اجاره :
                </div>
                <div class="item-value">
                  {{ demand.pricePerMeter }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="mb-6" dense>
            <v-col :cols="12">
              <div class="mb-2 font-weight-bold text-body-1 ">
                امکانات ملک درخواستی :
              </div>
            </v-col>
            <v-col
                v-for="(property,index) in demand.properties"
                :key="index"
                :cols="2">
              <v-icon color="main" size="20">
                mdi-check-circle-outline
              </v-icon>
              <span class="font-weight-bold text-body-2">
              {{ property.name }}
            </span>
            </v-col>
          </v-row>

          <v-row class="mb-6" dense>
            <v-col :cols="12">
              <div class="mb-4 font-weight-bold text-body-1 ">
                مناطق درخواستی و توضیحات :
              </div>
            </v-col>
            <v-col :cols="12">
              <v-chip
                  class="ml-3"
                  v-for="(location,index) in demand.locations"
                  :key="index"
                  color="main"
                  text-color="white">
                <v-icon size="20">
                  mdi-map-marker
                </v-icon>
                <span class="font-weight-bold text-body-2">
              {{ location.name }}
            </span>
              </v-chip>
            </v-col>
            <v-col :cols="12" class="mt-6">
              <div class="mb-4 font-weight-bold text-body-1 ">
                توضیحات :
              </div>
            </v-col>
            <v-col :cols="12">
              <div class=" font-weight-bold text-body-1 ">
                {{
                  demand.description
                }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>


      </v-card>

    </v-dialog>

  </div>
</template>

<script>

import Table from "@/components/Table"
import ViewButton from "@/components/ViewButton"
import EditButton from "@/components/EditButton"
import DeleteButton from "@/components/DeleteButton"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {AppConfig, getDataFromUrl, updateUrlQueries} from "@Newfiling/Services";
import {all, destroy} from "@Newfiling/module-demand/src/api";
import {addCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
    ViewButton,
    EditButton,
    DeleteButton,
  },

  data() {
    return {
      filters: {
        modal: false,
        take: 10,
        skip: 0,
        TypeOfTransaction: 0,
        PropertyType: 0,
        FloorNumber1: 0,
        Locationsid1: 0,
        MinimumSalesBudget: "",
        MaximumSalesBudget: "",
        MinimumRentBudget: "",
        MaximumRentBudget: "",
        Submittedusertype: 0,
        phone1: "",
        clientName: "",
      },
      fileTypes: [],
      fileTransactions: [],
      floorNumberItems: [],
      locations: [],
      table: {
        headers: [
          '#',
          'کاربر',
          'عنوان',
          'قیمت کل',
          'تاریخ ثبت',
          'وضعیت',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      demand: {
        modal: false,
        transactionType: null,
        fileProperty: null,
        username: null,
        phoneNumber: null,
        deadline: null,
        floorNumber: null,
        age: null,
        liquidityPercentage: null,
        numberofpeople: null,
        baseArea: null,
        visitDays: null,
        landArea: null,
        totalPrice: null,
        pricePerMeter: null,
        properties: [],
        locations: [],
        description: null,
      },
      daysOfWeek: [
        {
          id: 0,
          name: "شنبه"
        },
        {
          id: 1,
          name: "یکشنبه"
        },
        {
          id: 2,
          name: "دوشنبه"
        },
        {
          id: 3,
          name: "سه شنبه"
        },
        {
          id: 4,
          name: "چهار شنبه"
        },
        {
          id: 5,
          name: "پنج شنبه"
        },
        {
          id: 6,
          name: "جمعه"
        },
      ],
      properties: [],
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست درخواست ها',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig);
      this.fileTypes = appConfig.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.fileTransactions = appConfig.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name:item.Id === 1 ? 'خرید' :  item.Typeoftransaction,
        }
      })
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      this.floorNumberItems = appConfig.Floornumber.map(item => {
        return {
          id: item.Id,
          name: item.FloorName,
        }
      })
      this.properties = appConfig.DemandFeaturesTB.map(i => {
        return {
          id: i.Id,
          name: i.Persianname,
        }
      });
      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      }
      await this.fetchItems();
    })
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.filters.modal = false;
      this.table.loading = true;
      updateUrlQueries(this.$route.path, this.filters);
      try {
        const res = (await all({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          recordcount: 0,
          message: [],
        };
        const getTitle = (item) => {
          let title = this.fileTransactions.find(i => i.id === item.typeofdemand)?.name + " ";
          title = title + this.fileTypes.find(i => i.id === item.propertyType)?.name + " در ";
          title = title + this.locations.filter(i =>
              [item.locationsid1, item.locationsid2, item.locationsid3].includes(i.id))
              .map(i => i.name)
              .join(' ، ')
          return title;
        }
        this.table.items = res?.message.map(item => {
          return {
            clientName: item.clientName,
            title: getTitle(item),
            created_at: item.solarDateofRegistration,
            status: item.filestatus,
            amount: addCommas(item.minimumSalesBudget) + ' تا ' + addCommas(item.maximumSalesBudget),
            actions: {
              view: () => {
                this.demand = {
                  ...this.demand,
                  transactionType: this.fileTransactions.find(i => i.id === item.typeofdemand)?.name,
                  fileProperty: this.fileTypes.find(i => i.id === item.propertyType)?.name,
                  username: item.clientName,
                  phoneNumber: item.mobile,
                  deadline: item.deadline + ' روز ',
                  liquidityPercentage: (() => {
                    if (item.liquidityPercentage) {
                      return item.liquidityPercentage + ' % ';
                    }
                  })(),
                  numberofpeople: (() => {
                    if (item.numberofpeople) {
                      return item.numberofpeople + ' نفر'
                    }
                  })(),
                  visitDays: (() => {
                    return (this.daysOfWeek.find(i => i.id === item.startdayTheDayOfTheVisit)?.name ?? '')
                        + ' تا ' +
                        (this.daysOfWeek.find(i => i.id === item.enddayTheDayOfTheVisit)?.name ?? '')
                  })(),
                  baseArea: `${item.minmummetraj ?? ''} تا ${item.maximummetraj ?? ''} متر مربع `,

                  landArea:`${item.landarea1 ?? ''} تا ${item.landarea2 ?? ''} متر مربع `,
                  floorNumber: `${item.floornumber1 ?? ''} تا ${item.floornumber2 ?? ''}`,
                  age: `${item.minimumbuildingage ?? ''} تا ${item.maximumbuildingage ?? ''} سال `,
                  properties: this.properties.filter(i => item?.attributes?.split('-').map(j => parseInt(j)).includes(i.id)),
                  locations: this.locations.filter(i => [item.locationsid1, item.locationsid2, item.locationsid3].includes(i.id)),
                  description: item.description,
                  totalPrice: `${addCommas(item.minimumSalesBudget) ?? 0} تا ${addCommas(item.maximumSalesBudget) ?? 0} تومان `,
                  pricePerMeter: `${addCommas(item.minimumRentBudget) ?? 0} تا ${addCommas(item.maximumRentBudget) ?? 0} تومان `,
                  modal: true,
                  title: getTitle(item),
                }
              },

              edit: () => {
                this.$router.push('/panel/demands/edit/' + item.id)
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },
            }
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await destroy(this.deleteItem.id);
        this.$toast.success('درخواست مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },

    resetFilters() {
      this.filters = {
        ...this.filters,
        modal: false,
        take: 10,
        skip: 0,
        TypeOfTransaction: 0,
        PropertyType: 0,
        FloorNumber1: "",
        Locationsid1: 0,
        MinimumSalesBudget: "",
        MaximumSalesBudget: "",
        MinimumRentBudget: "",
        MaximumRentBudget: "",
        Submittedusertype: 0,
        phone1: "",
        clientName: "",
      }
      this.fetchItems();
    }
  }

}
</script>

<style>
.demand-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
</style>
